body{
    font-family: 'Roboto', sans-serif!important;
}

.main-panel{
    background: #f3f5f7;
}

.sidebar[data-color="black"]:after, .off-canvas-sidebar[data-color="black"]:after{
    background: rgb(78, 115, 223)!important;
}

.sidebar .nav li > a, .off-canvas-sidebar .nav li > a{
    opacity: 1;
    color: #fff;
}

.sidebar .nav i, .off-canvas-sidebar .nav i{
    color: #fff;
}

.sidebar[data-active-color="info"] .nav li.active > a, .sidebar[data-active-color="info"] .nav li.active > a i, .sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"], .sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] i, .sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon, .sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a, .off-canvas-sidebar[data-active-color="info"] .nav li.active > a, .off-canvas-sidebar[data-active-color="info"] .nav li.active > a i, .off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"], .off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] i, .off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon, .off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a{
    color: #fff;
}

.sidebar .sidebar-wrapper, .off-canvas-sidebar .sidebar-wrapper{
    padding-bottom: 0px;
    height: 100vh;
}

.card{
    box-shadow: none;
    border-radius: 10px!important;
    border-top: 1px solid rgb(227,230,240)!important;
    border-right: 1px solid rgb(227,230,240)!important;
    border-bottom: 1px solid rgb(227,230,240)!important;

}

.card-stats .card-body .numbers .card-category{
    color: #8687a7;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.card-stats .card-body .numbers p{
    color: #2E2545;
    font-size: 25px;
    font-weight: 700;
}

.card-stats .card-body{
    padding: 1.25rem!important;
}

.ak-card-digit{
    font-size: 25px;
    font-weight: 600;
}

.ak-sidebar p{
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.3px;
}

.card-stats .card-footer hr{
    background: rgba(134,135,167,0.2);
}

.card-stats .card-footer .stats{
    color: rgba(134,135,167,1);
}

.card .card-footer .stats i{
    color: #8687a7;
}

.nc-circle-10{
    color: rgb(78, 115, 223)!important;
}

.nc-money-coins{
    color: rgb(28,200,138)!important;
}

.nc-vector{
    color: rgb(246,194,62) !important;
}

.table-view .card-header .card-title{
    margin: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
}

.dataTables_length label{
    margin-bottom: 5px;
    color: #8687a7;
    letter-spacing: 1px;
    font-size: 0.875rem;
    font-weight: 500;
}

.dataTables_length label select{
    border: 1px solid #ced4da;
    color: #495057;
    font-weight: 500;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
    -webkit-appearance: none;
    appearance: none;
    padding: .2rem 1.75rem .2rem .75rem!important;
}

#example_filter label{
    font-size: 0.875rem;
    color: #8687a7;
    letter-spacing: 1px;
    font-weight: 500;
}

.dataTables_wrapper .dataTables_filter input{
    border: 1px solid #8687a7!important;
}

#example{
    padding-top: 15px;
}

#example thead{
    background: #f3f5f7;
}

#example thead tr th{
    border-top: 1px solid #eff2f7;
    border-bottom: 1px solid #eff2f7;
    color: #2e2545;
    letter-spacing: 0.5px;
    font-size: 14px;
}

#example tbody tr td{
    color: #8687a7!important;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

#example tbody tr td button{
    padding: 8px 10px;
}

#example tbody tr td button:focus{
    outline: none;
}

table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd{
    background-color: #fff!important;
}

table.dataTable.display tbody tr.odd>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd>.sorting_1{
    background-color: #fff!important;
}

.edit-icon{
    border: 0px;
    background: rgb(78, 115, 223);
    padding: 8px;
    border-radius: 3px;
    margin-right: 15px;
    color: #fff;
}

.delete-icon{
    background: rgb(255,113,91);
}

.green-icon{
    background: rgb(28,200,138);
}

.edit-icon img{
    width: 20px;
}

button:focus{
    outline: none;
}

.status select{
    padding: 3px;
    font-family: 'Roboto';
}

table.dataTable.no-footer{
    border-bottom: 1px solid #eff2f7!important;
}

.dataTables_wrapper .dataTables_paginate , .dataTables_info{
    padding-top: 25px!important;
}

#example_wrapper{
    overflow-x: scroll;
}

#example_wrapper::-webkit-scrollbar {
    width: 1px!important;
    height: 1px!important;
}
  
#example_wrapper::-webkit-scrollbar-track {
    background: #fff; 
}
   
#example_wrapper::-webkit-scrollbar-thumb {
    background: #3b5de7; 
}
  
#example_wrapper::-webkit-scrollbar-thumb:hover {
    background: #3b5de7; 
}

.head h5{
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 0.5px;
    margin-bottom: 25px;
}

.main-panel > .content{
    margin-top: 90px;
    padding: 0 30px 30px 30px;
}

.breadcrumb-item a{
    color: rgb(23, 43, 77);
    font-weight: 500;
    letter-spacing: 0.8px;
    font-size: 0.875rem;
}

.breadcrumb-item.active{
    color: rgb(107, 119, 140)!important;
    font-size: 0.875rem!important;
    font-weight: 500!important;
    letter-spacing: 0.8px!important;;
}

.page_head{
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.user_profile_main{
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,.125);
}

.form-control{
    height: 45px!important;
    display: block!important;
    padding: .47rem .75rem!important;
    font-size: 0.8rem!important;
    font-weight: 500!important;
    line-height: 1.5!important;
    color: #8687a7!important;
    background-color: rgb(248,249,252)!important;
    border: 1px solid #ced4da!important;
    border-radius: 2px!important;
    letter-spacing: 0.5px!important;
}

.form-label{
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #2e2545;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
    background-color: #eff2f7;
    border: 1px solid #ced4da;
}

.subscription_detail h5{
    font-size: 14px;
    font-weight: 600;
    color: #495057;
    letter-spacing: 0.8px;
}

.subscription_detail p{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
    color: #858796;
    margin-bottom: 0px;
    margin-top: 5px;
}

.blue-btn{
    background: rgb(78, 115, 223);
    padding: 8px 17px;
    color: #fff;
    border-radius: 3px;
    border: 1px solid #3b5de7!important;
    transition: 0.5s all;
}

.blue-btn:hover{
    background: transparent!important;
    color: rgb(78, 115, 223);
    border: 1px solid #3b5de7;
}

.add-btn{
    background: #45cb85;
    border: 0px;
    padding: 10px 15px;
    color: #fff;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 500;
    box-shadow: 0 0.75rem 2.5rem rgb(18 38 63 / 10%);
    border: 1px solid #45cb85;
    transition: 0.5s all;
}

.add-btn:hover{
    border: 1px solid #45cb85;
    background: transparent;
    color: #45cb85;
}

.add-btn:focus{
    outline: none;
}

.head{
    font-weight: 700;
    color: #2e2545;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    font-size: 22px;
    margin-bottom: 5px;
}

.breadcrumb{
    background-color: transparent!important;
    padding: 0px!important;
    margin-bottom: 0px!important;
}

.card .card-body{
    padding: 30px 20px;
}

.add-edit-team .modal-content{
    border-radius: 7px;
    border: 0px;
    box-shadow: 0px 0px 5px 0px rgba(72,72,72,0.5);
}

.form-group{
    width: 100%;
}

.form-group label{
    font-size: 14px;
    font-weight: 600;
    color: #2e2545;
    letter-spacing: 0.5px;
}

.form-group input{
    height: 45px;
    border-radius: 0px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #8687a7;
}

.form-group .form-control{
    padding-left: 20px;
}

.form-group input::placeholder , .form-group textarea::placeholder{
    color: #8687a7!important;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.5px;
}



.border-hr{
    border: 0.5px dashed rgba(134,135,137,0.4);
    width: 93%;
    margin-top: 0px;
}

.blue-btn{
    background: rgb(78, 115, 223)!important;
    border: 0px;
    padding: 10px 15px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 15px;
}

.red-btn{
    background: #FF6060!important;
    border: 1px solid #FF6060;
    padding: 10px 15px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #fff;
    text-transform: uppercase;
    transition: 0.5s all;
}

.red-btn:hover{
    background: transparent!important;
    border: 1px solid #FF6060;
    color: #FF6060;
}

.red-btn:focus , .blue-btn:focus{
    outline: none;
}

.upload_image{
    text-align: center;
}

.view_image{
    width: 100px!important;
    height: 100px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    background-size: cover;
    border: 5px solid #F8F8F8;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%);
    background: rgba(72,72,72,0.5);
    object-fit: cover;
}

/* .upload-icon-main{
    position: relative;
} */

.upload-icon-main{
    position: relative;
}

.upload-icon{
    position: absolute;
    top: -90px;
    opacity: 0;
    cursor: pointer;
    z-index: 999;
}

.custom_icon{
    width: 35px;
    height: 35px;
    position: absolute;
    background: #fff;
    top: -90px;
    right: 178px;
    border-radius: 50%;
    box-shadow: 0px 0px 8px 0px rgba(72,72,72,0.5);
}

.custom_icon img{
    padding: 6px 0px;
    width: 70%;
    background-size: cover;
    cursor: pointer;
}

.stadiumbox-main{
    border: 1px solid rgb(227, 230, 240);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    background: #fff;
}

.stadiumbox-main h5{
    margin-top: 10px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: rgb(78, 115, 223);
    text-transform: capitalize;
    margin-bottom: 15px;
    font-size: 16px;
}

.stadiumbox-main p{
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.8px;
    color: #8687a7;
}

.stadiumbox-main h6{
    text-transform: capitalize;
    letter-spacing: 0.8px;
    font-weight: 600;
    font-size: 14px;
    margin-top: 3px;
    margin-bottom: 15px;
    color: #2E2545;
}

.edit{
    background: transparent;
    border: 1px solid rgb(78, 115, 223);
    border-radius: 50px;
    padding: 8px 25px;
    font-weight: 600;
    color: rgb(78, 115, 223);
    border: 1px solid rgb(78, 115, 223);
    transition: 0.5s all;
}

.delete{
    background: rgb(78, 115, 223);
    border: 1px solid rgb(78, 115, 223);
    border-radius: 50px;
    padding: 8px 25px;
    color: #fff;
    font-weight: 600;
    transition: 0.5s all;
}

.edit:hover{
    background: rgb(78, 115, 223);
    border: 1px solid rgb(78, 115, 223);
    color: #fff;
}

.delete:hover{
    background: transparent;
    border: 1px solid rgb(78, 115, 223);
    color: rgb(78, 115, 223);
}

.edit:focus , .delete:focus{
    outline: none;
}

.stadium_image{
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.add-stadium select{
    width: 100%;
    height: 45px;
    border: 1px solid #ced4da;
    padding-left: 20px;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.tournaments-main{
    border: 1px solid rgb(227, 230, 240);
    margin-bottom: 20px;
    background: #fff;
    border-radius: 5px;
}

.tournaments-main h5{
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.9px;
    background: #4e73df;
    padding: 14px 0px;
    color: #fff;
    margin-bottom: 0px;
    border-radius: 5px 5px 0px 0px;
}

.tournaments-detail{
    padding: 15px;
}

.tournaments-detail .detail{
    display: flex;
    justify-content: space-between;
}

.tournaments-detail .detail p{
    font-size: 12px;
    font-weight: 500;
    color: #8687a7;
    letter-spacing: 0.5px;
}

.tournaments-detail .detail span{
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.8px;
    color: #2e2545;
}

.view_match{
    width: 100%;
    border: 0px;
    padding: 8px 25px;
    background: rgb(78, 115, 223);
    border: 1px solid rgb(78, 115, 223);
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.8px;
    border-radius: 3px;
    transition: 0.5s all;
    font-size: 12px;
}

.view_match:hover{
    background: transparent;
    border: 1px solid rgb(78, 115, 223);
    color: rgb(78, 115, 223);
}

.add_match{
    width: 100%;
    border: 0px;
    padding: 8px 25px;
    background: transparent;
    border: 1px solid rgb(78, 115, 223);
    color: rgb(78, 115, 223);
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 3px;
    transition: 0.5s all;
    font-size: 12px;
}

.add_match:hover{
    background: rgb(78, 115, 223);
    color: #fff;
}

.add-tournaments h5{
    font-size: 18px;
    font-weight: 700;
    color: #3b5de7;
    letter-spacing: 0.5px;
}

.box1{
    border-left: 4px solid rgb(78,115,223)!important;
}

.box2{
    border-left: 4px solid rgb(28,200,138)!important;
}

.box-3{
    border-left: 4px solid rgb(246,194,62)!important;
}

.navbar.navbar-transparent{
    background-color: #fff!important;
}

.stadium_edit{
    background: transparent;
    border: 1px solid rgb(78, 115, 223);
    border-radius: 50px;
    padding: 8px 25px;
    color: rgb(78, 115, 223);
    font-weight: 500;
    transition: 0.5s all;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.stadium_edit:hover{
    background: rgb(78, 115, 223);
    border: 1px solid rgb(78, 115, 223);
    color: #fff;
}

.stadium_delete{
    background: rgb(78, 115, 223);
    border: 1px solid rgb(78, 115, 223);
    border-radius: 50px;
    padding: 8px 25px;
    color: #fff;
    font-weight: 500;
    transition: 0.5s all;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.stadium_delete:hover{
    border: 1px solid rgb(78, 115, 223);
    color: rgb(78, 115, 223);
    background: transparent;
}

.upload-stadium-main{
    position: absolute;
    bottom: 15px;
    font-size: 12px;
    right: 5px;
}

.upload-stadium-main input{
    opacity: 1;
    z-index: 9999;
    cursor: pointer;
    background: #484848;
    padding: 8px;
    border-radius: 3px;
    color: #fff;
}

.upload-stadium-main .upload-stadium-section{
    background: #f5f5f5;
    position: absolute;
    width: 90%;
    bottom: -5px;
    border-radius: 3px;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    z-index: -1;
}

.dropdown-menu .dropdown-item:first-child{
    border-radius: 0px;
}

.dropdown-menu .dropdown-item:last-child{
    border-radius: 0px;
}

.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus{
    background: #4e73df;
}

.dropdown-menu .dropdown-item{
    font-size: 14px!important;
    letter-spacing: 0.5px;
    font-weight: 500;
    text-transform: capitalize;
}

.dropdown-toggle::after{
    color: rgb(78, 115, 223);
}

.logo{
    font-size: 20px!important;
    font-weight: 600!important;
    letter-spacing: 0.5px!important;
    color: #4e73df!important;
}

.selectbar{
    width: 100%;
    height: 45px;
    background-color: rgb(248,249,252)!important;
    padding: .47rem .75rem!important;
    border: 1px solid #ced4da!important;
    color: #8687a7;
}

textarea{
    width: 100%;
    background-color: rgb(248,249,252)!important;
    padding: .47rem .75rem!important;
    border: 1px solid #ced4da!important;
    color: #8687a7;
    font-size: 14px!important;
    font-weight: 500;
    letter-spacing: 0.5px;
}


.tournaments-section h5{
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    margin-bottom: 3px;
    color: #2E2545;
}

.tournaments-section p{
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #8687a7;
    margin-bottom: 0px;
}

.video-react .video-react-poster{
    background-size: cover!important;
}

.video-react .video-react-big-play-button{
    background-color: #4e73df;
    border-radius: 3px;
    border: 2px solid #fff;
}

.video-detail{
    border: 1px solid rgb(227, 230, 240);
    padding: 15px;
}

.video-content h5{
    font-size: 16px;
    color: #2E2545;
    font-weight: 600;
    letter-spacing: 0.8px;
    margin-bottom: 3px;
}

.video-content p{
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #8687a7;
    margin-bottom: 0px;
}

.video-content input{
    background: #f3f5f7;
    padding: 10px;
    border-radius: 3px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.breakdown-main h5{
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 600;
}

.wrapperClassName.rdw-editor-wrapper{
    background-color: #f8f9fc;
    padding: 15px;
    border: 1px solid #ced4da!important;
}

.prediction-head{
    font-size: 16px;
    font-weight: 600;
    color: #4e73df;
    letter-spacing: 0.8px;
    margin-bottom: 25px;
}


.profile-form{
    border: 1px solid rgb(227,230,240)!important;
}

.custom_icon1{
    right: 90px!important;
}

.change-password{
    font-weight: 700;
    letter-spacing: 0.5px;
}


@media(max-width: 991px){
    .user_profile_main{
        margin-top: 20px;
    }
    .add-main-btn{
        margin-top: 20px;
    }
    .tournament-btn{
        margin-bottom: 10px;
    }
}

@media(max-width: 576px){
    .stadiumbox-main img{
        width: 100%;
    }
    .stadium_delete{
        margin-top: 15px;
    }
    .tournament-btn{
        margin-bottom: 0px;
    }
    .tournament-btn .stadium_delete{
        margin-bottom: 15px;
    }
    .nfl:nth-child(2){
        margin-top: 20px;
    }
    .nfl:nth-child(3){
        margin-top: 20px;
    }
    .change{
        margin-top: 20px;
    }
}

.fbse-main h6{
    color: #2E2545;
    font-weight: 600;
    letter-spacing: 0.8px;
    font-size: 14px;
}

.fbse-head{
    color: #8687a7!important;
    font-weight: 500!important;
    letter-spacing: 0.8px!important;
}

.team-list .align-middle{
    position: relative;
}

.team-list .align-middle img{
    width: 40px;
}

.team-list .align-middle .status-circle{
    width: 10px;
    height: 10px;
    top: 2px;
    left: 32px;
    opacity: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.team-list .align-middle .d-inline-block span{
    line-height: 40px;
}

.vs{
    font-weight: 700;
    color: #2E2545;
}

.sport-head h5{
    font-size: 14PX;
    font-weight: 600;
    letter-spacing: 0.8PX;
    text-transform: capitalize;
    color: #1cc88a;
    margin-bottom: 0px;
}

.day-name{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
    color: #8687a7;
}

.fbse-head{
    color: #2e2545!important;
    letter-spacing: 0.3px!important;
    font-weight: 500!important;
}

.sportline{
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

@media(max-width:991px){
    .delete-btn-home{
        margin-top: 15px;
    }
    .date-video-content .video-content{
        margin-top: 15px;
    }
    .upload-video input{
        margin-top: 15px;
    }
}

.ak-sidebar .fas{
    font-size: 20px!important;
}

.selectbar option{
    margin: 15px 0px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
    background: linear-gradient(to bottom, #4e73df 0%, #4e73df 100%)!important;
    border: 0px!important;
    margin-left: 0px;
}

.sidebar .logo:after, .off-canvas-sidebar .logo:after{
    background-color: transparent;
}

.sidebar .logo a.logo-mini, .off-canvas-sidebar .logo a.logo-mini{
    margin: 0 auto;
    width: 70px;
    float: inherit;
}

.sidebar .nav, .off-canvas-sidebar .nav{
    margin-top: 0px;
}

.sidebar .nav, .off-canvas-sidebar .nav{
    height: 1000px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  .cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
   
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

.cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}



.video-react .video-react-big-play-button{
    top: 50%;
    left: 50%;
    margin-top: -0.75em;
    margin-left: -1.5em;
}
.react-datepicker-wrapper{
    width: 100%;
}
.sidebar .sidebar-wrapper {
    
    height: auto !important;
}
.error-msg{
    color: #c50f0f;
}
.electric_plug {
    height: auto !important;
}
.buttons-csv.buttons-html5{
    background-color: #51bcda !important;
    color:#fff;
    margin: 0px;
}